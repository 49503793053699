@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
body {
    margin: 0;
    font-family: 'Varela Round', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #EDE0E0;
    font-size: 0.9rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logo {
    width: auto;
    height: 22rem;
}

.navMenuItem {
    padding: 0.9rem;
    border-radius: 0.8rem;
    align-items: baseline;
    background-color: #decfcf;
    margin: 4px;
}

.bg-color2 {
    background-color: #ddcfcf;
}

.text-color1 {
    color: #EDE0E0;
}

.opsirnije {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

.navMenuItem:hover {
    background-color: #beaeae;
}

.active {
    background: #beaeae !important;
    border-radius: 10px;
    padding: 10px;
}

.decoHover:hover {
    background-color: #beaeae;
}

.tabela {
    background-color: #EDE0E0 !important;
}

.torta {
    background-color: #eadada;
    position: relative;
    transition: ease-out 300ms;
}

.torta:hover {
    background-color: rgb(245, 242, 235);
    /*margin: 3px;
    transform: scale(1.023);
    transition: ease-in 300ms;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    z-index: 10;*/
}



.readMoreIcon {
    transition: ease-out 300ms;
}

.torta:hover .readMoreIcon {
    transform: scale(1.4) translateX(2px);
    transition: ease-in 300ms;
}

.cijenaText {
    text-align: right;
    font-weight: bolder;
}

.veciFont {
    font-size: 1.1rem;
}

.cijenaTr {
    margin-top: -10px;
    padding: 20px;
    border-radius: 5px;
    background-color: #e5d6d6;
}

.cijenaTr:hover {
    background-color: #d4c2c2;
}

.loader {
    width: 150px;
    height: auto;
}

.alergenInfo {
    font-size: 1.3rem;
    align-items: first baseline;
}


/* .categoryMenu {
     border: 1px solid #beaeae;
    border-radius: 10px;
} */

.catMenu:hover {
    background-color: #d4c2c2;
}

.catMenu {
    border-radius: 10px;
    background-color: #d3d3d375;
}

.overflow-x-scroll {
    overflow-x: scroll;
    white-space: nowrap;
    /* Osigurava da se stavke ne lome */
    flex-wrap: nowrap;
    /* Osigurava da se stavke ne prelaze na više redova */
    -webkit-overflow-scrolling: touch;
    /* Dodaje glatko pomicanje na mobilnim uređajima */
    scrollbar-width: thin;
}

*::-webkit-scrollbar {
    width: 6px;
    /* Smanjite širinu scroll trake po potrebi */
    height: 6px;
    /* Smanjite visinu scroll trake po potrebi (za vertikalne scroll trake) */
}

*::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Boja thumb-a */
    border-radius: 3px;
    /* Zaobljeni uglovi thumb-a */
}

*::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Boja track-a */
}

.navbar-toggler {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 20;
}

.navbar-toggler:focus {
    border: none !important;
}

.btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
}

@media (min-width: 500px) {
    .element {
        overflow-x: hidden;
    }
}
